html {
    height: 100%;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
    background: #fff;
    height: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

div#root {
    height: 100%;
}

.pds-spinner {
    position: relative;
}
.pds-spinner div {
    position: absolute;
    background: rgb(38, 103, 131);
    animation: pds-spinner 1.2s linear infinite;
}
@keyframes pds-spinner {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
}
